import { Suspense, lazy, useEffect, useState } from 'react'
import './App.css'
import '@mantine/core/styles.css'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import LoadingSpinner from './lowLevel/LoadingSpinner'
import { ToastContainer } from 'react-toastify'
import '@mantine/dates/styles.css'
import '@mantine/charts/styles.css'
import 'react-toastify/dist/ReactToastify.css'
import { useAtom, useSetAtom } from 'jotai'
import { selectedInstance, userTokenAtom } from './store'
import { useIdleTimer } from 'react-idle-timer'
import { apiType, isUserTokenValid, preparePromise } from './helpers/ajaxH'
import { ApiData } from './types/Api'
import { notify } from './helpers/notifyH'
import Modals from './lowLevel/Modals'
import TextField from './lowLevel/TextField'
import FlexContainer from './lowLevel/FlexContainer'
import Button from './lowLevel/Button'
import ElemsRow from './lowLevel/ElemsRow'
import ErrorBoundary from './pages/ErrorBoundary'

const Login = lazy(() => import('./pages/Login'))
const IndexPage = lazy(() => import('./pages/IndexPage'))

function App() {
    const navigate = useNavigate()
    const [userToken, setUserToken] = useAtom(userTokenAtom)
    const [showIdealModal, setShowIdealModal] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isSignInLoading, setIsSignInLoading] = useState(false)
    const setInstance = useSetAtom(selectedInstance)

    const onIdle = () => {
        signOut()
    }

    const onPrompt = () => {
        setShowIdealModal(true)
    }

    useEffect(() => {
        checkForValidUserToken()
    }, [userToken])

    const { activate } = useIdleTimer({
        onIdle,
        onPrompt,
        timeout: 7200000, // logout user after 2hr
        promptBeforeIdle: 300000, // show prompt 5 min before timeout
        disabled: !userToken || userToken.length === 1,
    })

    const signOut = () => {
        setShowIdealModal(false)
        setUserToken('0')
        activate()
    }

    const checkForValidUserToken = () => {
        if (userToken.length <= 1) {
            setInstance({})
        }
        if (userToken === '1') {
            //Do nothing, user might be trying to reset his creds (forgot user name/pwd). This will avoid redirecting to log in page
        } else if (userToken === '2') {
            clearStorage()
            notify.error(
                'Your token has been invalidated. This might have occurred because you logged in to another session'
            )
            document.title = 'Log In'
        } else if (userToken === null || userToken === '' || userToken === '0') {
            if (userToken === '') {
                notify.success('Logged out successfully')
            }
            redirectToLogin()
            document.title = 'Log In'
        } else {
            isUserTokenValid(userToken)
                .then((tokenIsValid) => {
                    if (tokenIsValid === false) {
                        setUserToken('0')
                        notify.error('Session expired, please login again.')
                    }
                })
                .catch((e) => {
                    redirectToLogin()
                    notify.error('Something went wrong, please try logging in again.')
                })
        }
    }

    const redirectToLogin = () => {
        clearStorage()
        navigate('/login')
    }

    const staySignedIn = async () => {
        setIsSignInLoading(true)
        preparePromise(apiType.post, '_ASYS002A')
            .then((resp: ApiData[]) => {
                if (Array.isArray(resp) && resp[0]) {
                    setUserToken(resp[0].oToken)
                    setShowIdealModal(false)
                } else {
                    signOut()
                }
                activate()
            })
            .catch((e) => {
                notify.error('Something went wrong, please try logging in again.')
                signOut()
            })
            .finally(() => {
                setIsSignInLoading(false)
            })
    }

    const clearStorage = () => {
        sessionStorage.clear()
        localStorage.clear()
    }

    const renderIdealModal = () => {
        return (
            <Modals title="Session Expiration Warning" size="md" open={true} close={signOut}>
                <ElemsRow padding="md">
                    <TextField
                        textSize="md"
                        textWeight="medHeavy"
                        text="Your session is about to expire, because you have been inactive."
                    />
                </ElemsRow>
                <FlexContainer>
                    <Button text="Stay Logged in" color="Primary" isLoading={isSignInLoading} onClick={staySignedIn} />
                    <Button text="Log Out" color="Secondary" onClick={signOut} />
                </FlexContainer>
            </Modals>
        )
    }

    return (
        <ErrorBoundary setUserToken={setUserToken}>
            <Suspense fallback={<LoadingSpinner type="pageLevel" />}>
                {!userToken || userToken.length === 1 ? (
                    <>
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            {/* <Route path="/recover-username" element={<Login flag="username" />} /> */}
                            <Route path="/recover-password" element={<Login flag="password" />} />
                            <Route path="/mfareg" element={<Login flag="MFARegister" />} />
                            <Route path="/reset-password" element={<Login flag="resetPassword" />} />
                            <Route path="*" element={<Navigate to="/login" />} />
                        </Routes>
                        <ToastContainer />
                    </>
                ) : isLoading ? (
                    <LoadingSpinner type="pageLevel" />
                ) : (
                    <IndexPage />
                )}
                {showIdealModal && renderIdealModal()}
            </Suspense>
        </ErrorBoundary>
    )
}

export default App
