import { Loader, MantineSize, StyleProp, rem } from '@mantine/core';
import FlexContainer from './FlexContainer';

interface Props {
    size?: number | (string & {}) | MantineSize
    type?: 'pageLevel' | 'modal' | 'pageBody'
    customHeight?: StyleProp<React.CSSProperties['height']>
}

export default function LoadingSpinner(props: Props) {

    const processHeight = () => {
        if(props.type === 'pageLevel'){
            return '100vh'
        } else if(props.type === 'modal'){
            return '50vh'
        } else if(props.type === 'pageBody'){
            return `calc(100vh - ${rem(120)})`
        } else if(props.customHeight){
            return props.customHeight
        }
        return
    }


    return (
        <FlexContainer height={processHeight()}>
            <Loader height={"100%"} size={props.size ?? 'md'} />
        </FlexContainer>
    )
}
