import { MantineSize, Modal, ScrollArea } from '@mantine/core'
import FlexContainer from './FlexContainer'
import ElemsRow from './ElemsRow'
import TextField from './TextField'
import LoadingSpinner from './LoadingSpinner'

interface Props {
    title: string
    open: boolean
    close: () => void
    children?: any
    size?: number | (string & {}) | MantineSize
    isloading?: boolean
    showCrossIcon?: boolean
    closeOnClickOutside?: boolean
}

export default function Modals(props: Props) {
    return (
        <Modal
            radius="xl"
            centered
            scrollAreaComponent={ScrollArea.Autosize}
            opened={props.open}
            withCloseButton={props.showCrossIcon ?? true}
            onClose={props.close}
            title={<TextField textSize="xl" textWeight="heavy" text={props.title} />}
            size={props.size}
            closeOnClickOutside={props.closeOnClickOutside ?? false}
        >
            <FlexContainer>
                {props.isloading ? <LoadingSpinner type="modal" /> : <ElemsRow>{props.children}</ElemsRow>}
            </FlexContainer>
        </Modal>
    )
}
